import CustomTable, {
  CustomTableContentRow,
  CustomTableHeaderCol,
  CustomTableSettings,
} from 'components/tables/CustomTable'
import { VesselData } from 'interfaces/vessel'
import { getUTCDateString } from 'shared/date/getUTCDateString'
import { getUTCTimeString } from 'shared/date/getUTCTimeString'
import { useGetVesselLastTripsQuery } from 'store'
import classNames from 'classnames'

interface Props {
  vessel: VesselData | null
  startTime: number
  endTime: number
}

function StatusColorIndicator({ className }: { className: string }) {
  return (
    <>
      <div className="relative w-3 h-3">
        <div
          className={`w-[0.40rem] h-[0.40rem] absolute z-10 top-[0.19rem] left-[0.17rem] rounded-full ${className}`}
        ></div>
        <div className={`w-3 h-3 rounded-full ${className} opacity-35`}></div>
      </div>
    </>
  )
}

function PastTripsExpandable({ vessel, startTime, endTime }: Props) {
  const { data: trips, isFetching } = useGetVesselLastTripsQuery(
    { vesselId: vessel?.id || '', limit: 10 },
    { skip: !vessel?.id }
  )

  const tableHeaderData: CustomTableHeaderCol[] = [
    {
      id: 'departure',
      content: <p>Departure</p>,
    },
    {
      id: 'arrival',
      content: <p>Arrival</p>,
    },
    {
      id: 'duration',
      content: <p>Duration</p>,
    },
  ]

  const tableRowData: CustomTableContentRow[] =
    trips?.map((trip, index) => {
      const departure = `${getUTCDateString(new Date(trip.departureTime))}, ${getUTCTimeString(new Date(trip.departureTime))}`
      const arrival = trip.arrivalTime
        ? `${getUTCDateString(new Date(trip.arrivalTime))}, ${getUTCTimeString(new Date(trip.arrivalTime))}`
        : 'N/A'
      const durationInMilliseconds = trip.arrivalTime ? trip.arrivalTime - trip.departureTime : 0
      const durationDays = Math.floor(durationInMilliseconds / (1000 * 60 * 60 * 24))
      const durationHours = Math.floor((durationInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const durationMinutes = Math.floor((durationInMilliseconds % (1000 * 60 * 60)) / (1000 * 60))
      const duration =
        durationDays > 0
          ? `${durationDays}d ${durationHours}h ${durationMinutes}min`
          : `${durationHours}h ${durationMinutes}min`
      const status = trip.eta ? 'inProgress' : 'completed'

      const expandableContentData = [
        {
          title: 'Status',
          value: status === 'completed' ? 'Finished' : 'In progress',
        },
        {
          title: 'Departure port',
          value: trip.departurePort,
        },
        {
          title: 'Arrival port',
          value: trip.destinationPort,
        },
        {
          title: 'Departure time',
          value: departure,
        },
        {
          title: 'Arrival time',
          value: arrival,
        },
        {
          title: 'Duration',
          value: trip.arrivalTime ? duration : 'In progress',
        },
      ]

      return {
        id: trip.departureTime.toString(),
        cols: [
          {
            id: 'departure',
            value: departure,
            content: (
              <div className="flex flex-col">
                <div className="flex items-center gap-1 mb-1">
                  <StatusColorIndicator className={status === 'completed' ? 'bg-vibrant-fern' : 'bg-amber-glow'} />
                  <div>{trip.departurePort}</div>
                </div>
                <div className="text-silver-mist text-sm">{departure}</div>
              </div>
            ),
          },
          {
            id: 'arrival',
            value: arrival,
            content: (
              <div className="flex flex-col">
                <div>
                  <div>{trip.destinationPort}</div>
                </div>
                <div className="text-silver-mist text-sm">{arrival}</div>
              </div>
            ),
          },
          {
            id: 'duration',
            value: durationInMilliseconds,
            content: (
              <p className="bg-border-natural px-2 py-2 rounded">{trip.arrivalTime ? duration : 'In progress'}</p>
            ),
          },
        ],
        expandableContent: (
          <div className="flex">
            {expandableContentData.map((data, index) => (
              <div
                key={index}
                className={classNames('flex flex-col mt-4 mb-4 pr-5 border-r border-grayOutlineColor', {
                  'pl-5': index !== 0,
                })}
              >
                <p className="text-sm text-silver-mist">{data.title}</p>
                <p className="">{data.value}</p>
              </div>
            ))}
          </div>
        ),
      }
    }) || []

  const tripsVerb = trips?.length === 1 ? 'trip' : 'trips'
  const tableTextWithTrips = `${vessel?.name} had ${trips?.length || 0} ${tripsVerb} during the selected period.`
  const tableTextNoTrips = `No trips registered for the selected period.`

  const tableSettings: CustomTableSettings = {
    heading: 'Past trips',
    text: trips?.length ? tableTextWithTrips : tableTextNoTrips,
    horizontallyScrollable: true,
    contentClassName: 'max-h-96',
    expandableRows: true,
    searchable: true,
  }

  return (
    <div>
      <CustomTable
        settings={tableSettings}
        headerData={tableHeaderData}
        rowData={tableRowData}
        isLoading={isFetching}
      />
    </div>
  )
}
export default PastTripsExpandable
