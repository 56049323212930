import { VesselData } from 'interfaces/vessel'
import { useGetSpeedFuelEfficiencyInKnotsQuery } from 'store'
import ScatterChart from 'components/d3-charts/ScatterChart'
import ChartWrapper from 'components/chart/ChartWrapper'
import ToggleSwitch from 'components/form/ToggleSwitch'
import HexbinChart from 'components/d3-charts/HexbinChart'
import { useState, useMemo } from 'react'
import Loader from 'components/Loader'
import NoData from 'components/utility/NoData'

interface Props {
  vessel: VesselData
  startTime: number
  endTime: number
  offset: number
}

function SpeedOverGroundVsFuelConsumption({ vessel, startTime, endTime }: Props) {
  const { data, isFetching } = useGetSpeedFuelEfficiencyInKnotsQuery({
    vesselId: vessel.id,
    flowMeterId: vessel.main_engines[0].flowMeter[0].sensorId,
    startTime: startTime,
    endTime: endTime,
    isSplit: vessel.main_engines[0].flowMeter[0].split,
  })

  const scatterData = useMemo(
    () =>
      Array.isArray(data)
        ? data.map((item: any) => ({
            nameX: item.nameX,
            nameY: item.nameY,
            nameZ: item.nameZ,
            timestamp: item.timestamp,
            valueX: item.valueX,
            valueY: item.valueY,
            valueZ: item.valueZ,
          }))
        : [],
    [data]
  )

  const [showScatterChartSections, setShowScatterSections] = useState(true)
  const [showHexbinChartSections, setShowHexbinSections] = useState(true)

  return (
    <div className="flex flex-row gap-4">
      <ChartWrapper
        title="Speed over ground vs Fuel Consumption (Fuel efficiency as Color)"
        className="h-120"
        tooltipText="Scatter Plot of Speed, Fuel Consumption, and Fuel Efficiency Trends for the selected time period."
        tooltipId="speed-over-ground-vs-fuel-consumption-scatter-tooltip"
      >
        <div className="flex flex-1 h-full rounded-lg items-center flex-col justify-center">
          {isFetching ? (
            <Loader />
          ) : scatterData.length === 0 ? (
            <NoData
              type="warning"
              title="No data available for selected period"
              text="Please, try different date range."
            />
          ) : (
            <>
              <ScatterChart
                data={scatterData}
                showChartSections={showScatterChartSections}
                massFlowRateLowerThreshold={vessel.main_engines[0].massFlowRateLowerThreshold}
                massFlowRateUpperThreshold={vessel.main_engines[0].massFlowRateUpperThreshold}
              />
              <div className="flex flex-row gap-2">
                <p>Company Policy Overlay</p>
                <ToggleSwitch
                  value={showScatterChartSections}
                  onChange={() => {
                    setShowScatterSections(!showScatterChartSections)
                  }}
                ></ToggleSwitch>
              </div>
            </>
          )}
        </div>
      </ChartWrapper>
      <ChartWrapper
        title="Speed over ground vs Fuel Consumption"
        className="h-120"
        tooltipText="Hex-Bin Plot of Speed Over Ground vs Fuel Consumption with Frequency Distribution for the selected time period."
        tooltipId="speed-over-ground-vs-fuel-consumption-hexbin-tooltip"
      >
        <div className="flex flex-1 h-full rounded-lg items-center flex-col justify-center">
          {isFetching ? (
            <Loader />
          ) : scatterData.length === 0 ? (
            <NoData
              type="warning"
              title="No data available for selected period"
              text="Please, try different date range."
            />
          ) : (
            <>
              <HexbinChart
                data={scatterData}
                showChartSections={showHexbinChartSections}
                massFlowRateLowerThreshold={vessel.main_engines[0].massFlowRateLowerThreshold}
                massFlowRateUpperThreshold={vessel.main_engines[0].massFlowRateUpperThreshold}
              />
              <div className="flex flex-row gap-2">
                <p>Company Policy Overlay</p>
                <ToggleSwitch
                  value={showHexbinChartSections}
                  onChange={() => {
                    setShowHexbinSections(!showHexbinChartSections)
                  }}
                ></ToggleSwitch>
              </div>
            </>
          )}
        </div>
      </ChartWrapper>
    </div>
  )
}

export default SpeedOverGroundVsFuelConsumption
