import BarChart, { BarChartDataWithExtras } from 'components/chart/BarChart'
import DoughnutChart, { DoughnutChartData } from 'components/chart/DoughnutChart'
import { BarChartData, PerformanceBarChartData } from 'interfaces/graphApi'
import { VesselData } from 'interfaces/vessel'
import { useEffect, useMemo } from 'react'
import { chartColorCodes } from 'shared/chart/chartColorCodes'
import { useGetPerformanceBarChartInRangeQuery } from 'store/apis/totalsGraphApi'

interface Props {
  vessel: VesselData
  startTime: number
  endTime: number
  offset: number
  tooltipText?: string
  tooltipId?: string
}

function Performance({ vessel, startTime, endTime, offset, tooltipText, tooltipId }: Props) {
  const {
    data: apiData,
    isFetching,
    error,
  } = useGetPerformanceBarChartInRangeQuery(
    {
      vesselId: vessel.id,
      mainEngineFlowMeterId: vessel.main_engines[0].flowMeter[0].sensorId,
      isSplit: vessel.main_engines[0].flowMeter[0].split,
      startTime: startTime,
      endTime: endTime,
      massFlowRateUpperThreshold: vessel.main_engines[0].massFlowRateUpperThreshold,
      massFlowRateLowerThreshold: vessel.main_engines[0].massFlowRateLowerThreshold,
    },
    { skip: startTime === 0 || endTime === 0 }
  )

  const hasNonZeroValues = useMemo(() => {
    if (!apiData?.data) return false
    return apiData.data.some(
      (item) =>
        item.value.belowLimit > 0 || item.value.inRange > 0 || item.value.aboveLimit > 0 || item.value.maneuvering > 0
    )
  }, [apiData])

  const dataAvalable = !!apiData?.data && apiData?.data.length > 0 && hasNonZeroValues

  const barChartData: BarChartDataWithExtras[] = useMemo(
    () =>
      apiData
        ? [
            {
              label: 'belowLimit',
              unitOfMeasurement: apiData.unitOfMeasurement,
              unitOfRange: apiData.unitOfRange,
              data: apiData.data.map((item) => ({
                category: item.category,
                value: item.value.belowLimit,
              })),
              color: `rgb(${chartColorCodes.orange})`,
              title: 'Below Limit',
            },
            {
              label: 'inRange',
              unitOfMeasurement: apiData.unitOfMeasurement,
              unitOfRange: apiData.unitOfRange,
              data: apiData.data.map((item) => ({
                category: item.category,
                value: item.value.inRange,
              })),
              color: `rgb(${chartColorCodes.green})`,
              title: 'In Range',
            },
            {
              label: 'aboveLimit',
              unitOfMeasurement: apiData.unitOfMeasurement,
              unitOfRange: apiData.unitOfRange,
              data: apiData.data.map((item) => ({
                category: item.category,
                value: item.value.aboveLimit,
              })),
              color: `rgb(${chartColorCodes.red})`,
              title: 'Above Limit',
            },
            {
              label: 'maneuvering',
              unitOfMeasurement: apiData.unitOfMeasurement,
              unitOfRange: apiData.unitOfRange,
              data: apiData.data.map((item) => ({
                category: item.category,
                value: item.value.maneuvering,
              })),
              color: `rgb(${chartColorCodes.darkGray})`,
              title: 'Maneuvering',
            },
          ]
        : [],
    [apiData]
  )

  const doughnutChartData: DoughnutChartData[] = useMemo(
    () => [
      {
        name: 'Below Limit',
        value: apiData?.totalPercentage.belowLimit || 0,
        unit: '%',
        color: `rgb(${chartColorCodes.orange})`,
      },
      {
        name: 'In Range',
        value: apiData?.totalPercentage.inRange || 0,
        unit: '%',
        color: `rgb(${chartColorCodes.green})`,
      },
      {
        name: 'Above Limit',
        value: apiData?.totalPercentage.aboveLimit || 0,
        unit: '%',
        color: `rgb(${chartColorCodes.red})`,
      },
      {
        name: 'Maneuvering',
        value: apiData?.totalPercentage.maneuvering || 0,
        unit: '%',
        color: `rgb(${chartColorCodes.darkGray})`,
      },
    ],
    [apiData]
  )

  return (
    <div className="flex gap-4">
      <div className="w-80 xl:w-96 3xl:w-[28rem]">
        <DoughnutChart
          data={doughnutChartData}
          loading={isFetching}
          title="Summary of Policy Adherence"
          className="h-72"
          unitForLegend
          dataAvailable={dataAvalable}
          label="Percentage"
          tooltipText={tooltipText}
          tooltipId={tooltipId}
        />
      </div>
      <BarChart
        data={barChartData}
        title="Percentage distribution of Policy Adherence"
        loading={isFetching}
        offset={offset}
        className="h-72"
        dataAvailable={dataAvalable}
        maxY={100}
        yAxisText="Percentage distribution"
        hideYAxisLabels={true}
        yAxisTitlePadding={16}
        tooltipText={tooltipText}
        tooltipId={tooltipId}
        showTime={true}
      />
    </div>
  )
}

export default Performance
