import React from 'react'
import { CustomTableContentRow } from './tables/CustomTable'
import Loader from './Loader'
import classNames from 'classnames'

interface GridViewProps {
  data: CustomTableContentRow[]
  isLoading?: boolean
  settings?: GridViewSettings
}

interface GridViewSettings {
  cardClassName?: string
  gridClassName?: string
  renderCard?: (row: CustomTableContentRow) => React.ReactNode
}

const GridView = ({ data, isLoading, settings }: GridViewProps) => {
  const defaultCardRenderer = (row: CustomTableContentRow) => {
    return (
      <div
        key={row.id}
        className={classNames(
          'bg-white rounded-lg p-4 border border-grayOutlineColor hover:shadow-lg transition-shadow',
          settings?.cardClassName,
          { 'cursor-pointer': row.onRowClick }
        )}
        onClick={row.onRowClick}
      >
        {row.cols.map((col) => (
          <div
            key={col.id}
            className="mb-2"
          >
            {col.content}
          </div>
        ))}
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className="w-full flex justify-center mt-5 mb-5">
        <Loader />
      </div>
    )
  }

  return (
    <div
      className={classNames(
        'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4 p-4',
        settings?.gridClassName
      )}
    >
      {data.map((row) => (
        <React.Fragment key={row.id}>
          {settings?.renderCard ? settings.renderCard(row) : defaultCardRenderer(row)}
        </React.Fragment>
      ))}
    </div>
  )
}

export default GridView
