import { useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'
import { createChartAxes } from './chart-components/ChartAxes'
import { createChartSections } from './chart-components/ChartSections'
import { Tooltip } from './chart-components/Tooltip'
import { createColorLegend } from './chart-components/ColorLegend'

interface DataPoint {
  nameX: string
  nameY: string
  nameZ: string
  timestamp: number | null
  valueX: number
  valueY: number
  valueZ: number
}

interface Props {
  data: DataPoint[]
  margin?: { top: number; right: number; bottom: number; left: number }
  showChartSections?: boolean
  massFlowRateLowerThreshold?: number
  massFlowRateUpperThreshold?: number
}

const ScatterChart = ({
  data,
  margin = { top: 5, right: 100, bottom: 60, left: 60 },
  showChartSections = true,
  massFlowRateLowerThreshold,
  massFlowRateUpperThreshold,
}: Props) => {
  const svgRef = useRef<SVGSVGElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const updateDimensions = () => {
      if (!containerRef.current) return
      const { width, height } = containerRef.current.getBoundingClientRect()
      setDimensions({ width, height })
    }

    updateDimensions()

    const resizeObserver = new ResizeObserver(updateDimensions)
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current)
    }

    return () => resizeObserver.disconnect()
  }, [])

  useEffect(() => {
    if (!data || !svgRef.current || !containerRef.current || dimensions.width === 0 || dimensions.height === 0) return

    // Clear previous chart
    d3.select(svgRef.current).selectAll('*').remove()

    const { width, height } = dimensions

    // Create SVG
    const svg = d3.select(svgRef.current).attr('width', width).attr('height', height)

    // Create scales
    const xScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.valueX) || 0])
      .range([margin.left, width - margin.right])
      .nice()

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.valueY) || 0])
      .range([height - margin.bottom, margin.top])
      .nice()

    const groupedData = d3.group(
      data,
      (d) => Math.round(xScale(d.valueX) * 10) / 10,
      (d) => Math.round(yScale(d.valueY) * 10) / 10
    )

    const aggregatedData = Array.from(groupedData, ([x, yGroups]) =>
      Array.from(yGroups, ([y, points]) => ({
        x: +x,
        y: +y,
        count: points.length,
        avgZ: d3.mean(points, (d) => d.valueZ) || 0,
        nameX: points[0].nameX,
        nameY: points[0].nameY,
        nameZ: points[0].nameZ,
      }))
    ).flat()

    const sortedZValues = data.map((d) => d.valueZ).sort(d3.ascending)
    const cutoff = d3.quantile(sortedZValues, 0.99) || d3.max(data, (d) => d.valueZ) || 0
    const colorScale = d3
      .scaleSequential()
      .domain([d3.min(data, (d) => d.valueZ) || 0, cutoff])
      .interpolator(d3.interpolateViridis)

    if (showChartSections) {
      createChartSections({
        svg,
        width,
        height,
        margin,
        yScale,
        massFlowRateLowerThreshold,
        massFlowRateUpperThreshold,
      })
    }

    createChartAxes({
      svg,
      width,
      height,
      margin,
      xScale,
      yScale,
      xAxisLabel: data[0]?.nameX,
      yAxisLabel: data[0]?.nameY,
      y1AxisLabel: data[0]?.nameZ,
    })

    createColorLegend({
      svg,
      width,
      height,
      margin,
      colorScale,
    })

    const circles = svg
      .selectAll('circle')
      .data(aggregatedData)
      .enter()
      .append('circle')
      .attr('cx', (d) => d.x)
      .attr('cy', (d) => d.y)
      .attr('r', (d) => Math.max(1.5, Math.min(5, 1.5 + Math.log(d.count))))
      .attr('fill', (d) => colorScale(d.avgZ))
      .attr('opacity', 1)
  }, [data, dimensions, margin, showChartSections, massFlowRateLowerThreshold, massFlowRateUpperThreshold])

  return (
    <div
      ref={containerRef}
      style={{ width: '100%', height: '100%' }}
    >
      <svg
        ref={svgRef}
        style={{ width: '100%', height: '100%' }}
      ></svg>
    </div>
  )
}

export default ScatterChart
