import { ThunkAction } from '@reduxjs/toolkit'
import { BarChartData, GraphQueryArgs } from 'interfaces/graphApi'
import { RootState } from 'store'
import { totalsGraphApi } from 'store/apis/totalsGraphApi'

export const selectTotalFuelConsumedBarChartInRangeByArgs = (
  args: GraphQueryArgs
): ThunkAction<BarChartData[] | undefined, RootState, unknown, any> => {
  return (dispatch: any, getState: () => RootState) => {
    const state = getState()
    const queryState = totalsGraphApi.endpoints.getTotalFuelConsumedBarChartInRange.select(args)(state)
    const data: BarChartData[] | undefined = queryState.data
    return data
  }
}
