import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'
import { functions } from '../../firebase'
import { httpsCallable } from 'firebase/functions'
import { GraphQueryArgs, RangeAreaGraphData } from 'interfaces/graphApi'

const areaGraphApi = createApi({
  reducerPath: 'areaGraphApi',
  baseQuery: fakeBaseQuery(),
  keepUnusedDataFor: 600,
  endpoints(build) {
    return {
      getMassFlowRateRangeArea: build.query<any, GraphQueryArgs>({
        queryFn: async ({ vesselId, flowMeterId, startTime, endTime, isSplit }: GraphQueryArgs) => {
          try {
            const getData = httpsCallable(functions, 'getMassFlowRateRangeArea')
            const result: any = await getData({ vesselId, flowMeterId, startTime, endTime, isSplit })
            return { data: result.data[0] }
          } catch (error) {
            return { error }
          }
        },
      }),
    }
  },
})

export const { useGetMassFlowRateRangeAreaQuery } = areaGraphApi
export { areaGraphApi }
