import { RootState, useGetVesselLastTripsQuery } from 'store'
import { useAppSelector } from 'hooks/useReduxHooks'
import TripMapSection from './components/TripMapSection'
import PastTrips from '../components/PastTrips'
import { useEffect, useState } from 'react'
import SpeedOverGroundVsFuelConsumption from '../components/SpeedOverGroundVsFuelConsumption'
import MassFlowRateDistribution from '../components/MassFlowRateDistribution'
import { shouldAnonymizeData } from 'utils/anonymizeData'

function TripReport() {
  const [selectedTripId, setSelectedTripId] = useState<string>('')

  const selectedVessel = useAppSelector((state: RootState) => {
    return state.app.selectedVessel
  })

  const user = useAppSelector((state: RootState) => {
    return state.auth.user
  })

  const shouldAnonymize = shouldAnonymizeData(user?.email)

  const { data: trips, isFetching } = useGetVesselLastTripsQuery(
    { vesselId: selectedVessel?.id || '', limit: 50 },
    { skip: !selectedVessel?.id }
  )

  const { offset } = useAppSelector((state: RootState) => {
    return state.app.selectedTimezone
  })

  useEffect(() => {
    if (trips && trips.length > 0 && !selectedTripId && !document.location.hash) {
      setSelectedTripId(trips[0].id)
    }
  }, [trips])

  const selectedTrip = trips?.find((trip) => trip.id === selectedTripId)
  const selectedTrips = selectedTrip ? [selectedTrip] : []

  const startTime = selectedTrip?.departureTime || 0
  const endTime = selectedTrip?.status === 0 ? Date.now() : selectedTrip?.arrivalTime || 0

  function changeSelectedTripId(tripId: string) {
    if (selectedTripId === tripId) {
      setSelectedTripId('')
    } else {
      setSelectedTripId(tripId)
    }
  }

  return (
    <div className="flex flex-col gap-4 pb-6">
      <div className="mt-4">
        {selectedVessel && (
          <div className="mb-8">
            <PastTrips
              vessel={selectedVessel}
              trips={trips || []}
              selectedTrips={selectedTrips}
              onTripCheck={changeSelectedTripId}
              isFetching={isFetching}
              offset={offset || 0}
              shouldAnonymize={shouldAnonymize}
            />
          </div>
        )}

        {selectedVessel && (
          <div className="mb-8">
            <TripMapSection
              vessel={selectedVessel}
              startTime={startTime}
              endTime={endTime}
              offset={0 || 0}
            />
          </div>
        )}

        {selectedVessel && (
          <div className="mb-8">
            <SpeedOverGroundVsFuelConsumption
              vessel={selectedVessel}
              startTime={startTime}
              endTime={endTime}
              offset={0 || 0}
            />
          </div>
        )}

        {selectedVessel && (
          <div className="mb-8">
            <MassFlowRateDistribution
              vessel={selectedVessel}
              startTime={startTime}
              endTime={endTime}
              offset={offset || 0}
              tooltipText="Mass Flow Rate Distribution Over Time with Percentage Intensity Across 2-Hour Intervals"
              tooltipId="mass-flow-rate-tooltip"
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default TripReport
