import { resetLineChartState, RootState } from 'store'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import ShaftSpeedAndPower from './components/ShaftSpeedAndPower'
import FuelConsumption from './components/FuelConsumption'
import Speed from './components/Speed'
import FuelConsumptionVsSpeed from './components/FuelConsumptionVsSpeed'
import { useEffect } from 'react'

function Overview() {
  const dispatch = useAppDispatch()
  const { startTime, endTime } = useAppSelector((state: RootState) => {
    return state.app.dateRange
  })
  const { offset } = useAppSelector((state: RootState) => {
    return state.app.selectedTimezone
  })
  const selectedVessel = useAppSelector((state: RootState) => {
    return state.app.selectedVessel
  })

  useEffect(() => {
    dispatch(resetLineChartState())
  }, [])

  return (
    <div className="flex flex-col gap-4 pb-6">
      <div className="mt-4">
        {selectedVessel && (
          <div className="mb-8">
            <ShaftSpeedAndPower
              vessel={selectedVessel || null}
              startTime={startTime}
              endTime={endTime}
              offset={offset || 0}
            />
          </div>
        )}

        {selectedVessel && (
          <div className="mb-8">
            <FuelConsumption
              vessel={selectedVessel || null}
              startTime={startTime}
              endTime={endTime}
              offset={offset || 0}
            />
          </div>
        )}

        {selectedVessel && (
          <div className="mb-8">
            <Speed
              vessel={selectedVessel || null}
              startTime={startTime}
              endTime={endTime}
              offset={offset || 0}
            />
          </div>
        )}

        {selectedVessel && (
          <div className="mb-8">
            <FuelConsumptionVsSpeed
              vessel={selectedVessel || null}
              startTime={startTime}
              endTime={endTime}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Overview
