interface TrendLineProps {
  angle: number
  isPositive: boolean
  color: string
  chartArea: {
    top: number
    bottom: number
    left: number
    right: number
    height: number
    width: number
  }
}

export function TrendLine({ angle, isPositive, color, chartArea }: TrendLineProps) {
  const rotation = isPositive ? -angle : angle
  
  const lineStyle: React.CSSProperties = {
    position: 'absolute',
    top: chartArea.top + chartArea.height / 2,
    left: chartArea.left,
    width: chartArea.width,
    height: '3px',
    backgroundColor: 'transparent',
    transform: `translateY(-50%) rotate(${rotation}deg)`,
    transformOrigin: 'center center',
    opacity: 0.3,
    backgroundImage: `repeating-linear-gradient(
      90deg,
      ${color},
      ${color} 10px,
      transparent 10px,
      transparent 20px
    )`,
    pointerEvents: 'none',
    zIndex: 0
  }

  return <div style={lineStyle} />
}