import * as d3 from 'd3'

interface Section {
  from: number
  to: number
  color: string
}

interface ChartSectionsConfig {
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined>
  width: number
  height: number
  margin: { top: number; right: number; bottom: number; left: number }
  yScale: d3.ScaleLinear<number, number>
  massFlowRateLowerThreshold?: number
  massFlowRateUpperThreshold?: number
}

export function createChartSections({
  svg,
  width,
  height,
  margin,
  yScale,
  massFlowRateLowerThreshold = 187.5,
  massFlowRateUpperThreshold = 205,
}: ChartSectionsConfig) {
  const sections: Section[] = [
    { from: 0, to: massFlowRateLowerThreshold, color: '#F5A445' },
    { from: massFlowRateLowerThreshold, to: massFlowRateUpperThreshold, color: '#21A32E' },
    { from: massFlowRateUpperThreshold, to: 800, color: '#F54545' },
  ]

  svg
    .append('defs')
    .append('clipPath')
    .attr('id', 'chart-area')
    .append('rect')
    .attr('x', margin.left)
    .attr('y', margin.top)
    .attr('width', width - margin.left - margin.right)
    .attr('height', height - margin.top - margin.bottom)

  const sectionsGroup = svg.append('g').attr('clip-path', 'url(#chart-area)')

  const processedSections = sections.map((section) => ({
    yStart: section.from,
    yEnd: section.to,
    color: section.color,
  }))

  processedSections.forEach((section) => {
    sectionsGroup
      .append('rect')
      .attr('x', margin.left)
      .attr('y', yScale(section.yEnd))
      .attr('width', width - margin.left - margin.right)
      .attr('height', yScale(section.yStart) - yScale(section.yEnd))
      .attr('fill', section.color)
      .attr('opacity', 0.2)

    svg
      .append('line')
      .attr('x1', margin.left)
      .attr('x2', width - margin.right)
      .attr('y1', yScale(section.yEnd))
      .attr('y2', yScale(section.yEnd))
      .attr('stroke', '#666')
      .attr('stroke-width', 1)
      .attr('stroke-dasharray', '4,4')
      .attr('opacity', 1)
  })

  return processedSections
}
