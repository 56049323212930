import {
  Chart as ChartJS,
  LinearScale,
  LineElement,
  Legend,
  CategoryScale,
  Filler,
  Tooltip as ChartTooltip,
} from 'chart.js'
import ChartWrapper from 'components/chart/ChartWrapper'
import { TrendLine } from 'components/chart/TrendLine'
import Loader from 'components/Loader'
import NoData from 'components/utility/NoData'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { Scales } from 'interfaces/chart'
import { VesselData } from 'interfaces/vessel'
import { useEffect, useState, useMemo, useCallback } from 'react'
import { Chart } from 'react-chartjs-2'
import { getLineChartDataset } from 'shared/chart/getLineChartDataset'
import getLineChartOptions from 'shared/chart/getLineChartOptions'
import getLineChartScaleX from 'shared/chart/getLineChartScaleX'
import getLineChartScaleY from 'shared/chart/getLineChartScaleY'
import { changeChartIsRendering, RootState, useGetTotalFuelEfficiencyInTimeQuery } from 'store'
import lineChartWorker from 'workers/lineChartWorker'

ChartJS.register(LinearScale, LineElement, CategoryScale, Legend, ChartTooltip, Filler)
ChartJS.defaults.font.family = 'Lato'
ChartJS.defaults.font.size = 14

interface Props {
  vessel: VesselData
  startTime: number
  endTime: number
  offset: number
}

interface ChartDataset {
  label: string
  data: number[]
  borderColor: string
  backgroundColor: string
  fill: boolean
  yAxisID: string
  pointRadius: number
  pointHoverRadius: number
  timestamps?: number[]
}

interface ChartData {
  labels: string[]
  datasets: ChartDataset[]
}

interface ChartOptions {
  chartOptions: any // You can make this more specific if needed
  chartData: ChartData
}

const debounce = (func: Function, wait: number) => {
  let timeout: NodeJS.Timeout
  return function executedFunction(...args: any[]) {
    const later = () => {
      clearTimeout(timeout)
      func(...args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

// Move outside component to prevent recreation on each render
const debouncedChartUpdate = debounce((chart: any, setChartArea: Function) => {
  if (chart?.chartArea) {
    setChartArea(chart.chartArea)
  }
}, 100)

function FuelEfficiency({ vessel, startTime, endTime, offset }: Props) {
  const dispatch = useAppDispatch()
  const [lineData, setLineData] = useState<any>(null)
  const chartIsRendering = useAppSelector((state: RootState) => state.app.chartIsRendering)
  const lineChartTickCount = useAppSelector((state: RootState) => state.app.lineChartTickCount)
  const chartTitle = 'Fuel efficiency [kg/nm]'
  const [chartArea, setChartArea] = useState<any>(null)
  const [showTrendLine, setShowTrendLine] = useState(true)

  const { data: response, isFetching } = useGetTotalFuelEfficiencyInTimeQuery({
    vesselId: vessel.id,
    allEngineFlowMeters: [vessel.main_engines[0].flowMeter[0]],
    startTime: startTime,
    endTime: endTime,
  })

  const dataAvailable = !!response?.data && response.data.length > 0

  let chartDataAndOptions: ChartOptions | null = null
  const vesselLastUpdated = vessel?.last_updated?._seconds || 0

  chartDataAndOptions = useMemo(() => {
    if (!lineData) return null

    const { xlabels, timestamps, dataset1Values, dataset2Values } = lineData

    const isPositiveOrZero = (response?.trend ?? 0) <= 0
    const lineColor = isPositiveOrZero ? '#118F1E' : '#E11D48'
    const fillColor = isPositiveOrZero ? 'rgba(17, 143, 30, 0.3)' : 'rgba(225, 29, 72, 0.3)'

    const datasets: any = []
    if (dataset1Values?.length) {
      datasets.push(
        getLineChartDataset(chartTitle, dataset1Values, timestamps, vesselLastUpdated, lineColor, 'y', fillColor)
      )
    }

    const scales: Scales = {}
    if (xlabels?.length) {
      scales.x = getLineChartScaleX(xlabels, lineChartTickCount)
      if (dataset1Values?.length) {
        scales.y = getLineChartScaleY(chartTitle)
      }
    }

    // Get base options once
    const baseOptions = getLineChartOptions(scales, timestamps, vesselLastUpdated, 'Analytics-FuelEfficiency', true)

    return {
      chartData: {
        labels: Array.from({ length: dataset1Values.length || dataset2Values.length }, () => ''),
        datasets,
      },
      chartOptions: {
        ...baseOptions,
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: 0, // Disable animations for better performance
        },
        plugins: {
          ...baseOptions.plugins,
          legend: {
            ...baseOptions.plugins?.legend,
            labels: {
              ...baseOptions.plugins?.legend?.labels,
              generateLabels: (chart: any) => {
                const defaultLabels = ChartJS.defaults.plugins.legend.labels.generateLabels(chart)

                const trendLabel = {
                  ...defaultLabels[0],
                  text: `Trend: ${response?.trend?.toFixed(1)}%`,
                  fillStyle: 'transparent',
                  strokeStyle: lineColor,
                  lineWidth: 2,
                  hidden: !showTrendLine,
                  index: 1,
                  lineDash: [5, 5],
                  textDecoration: !showTrendLine ? 'line-through' : undefined,
                }

                return [...defaultLabels, trendLabel]
              },
            },
            onClick: (e: any, legendItem: any, legend: any) => {
              if (legendItem.index === 1) {
                setShowTrendLine(!showTrendLine)
                legend.chart.update()
              }
            },
          },
        },
      },
    }
  }, [lineData, response?.trend, showTrendLine, vesselLastUpdated, lineChartTickCount])

  const loading = chartIsRendering || isFetching || !chartDataAndOptions

  useEffect(() => {
    setLineData(null)
  }, [startTime, endTime])

  useEffect(() => {
    if (isFetching) return
    const worker = new Worker(lineChartWorker)

    // Add type guard to ensure data exists and has length
    const data = response?.data
    const dataLength = data?.length ?? 0

    // Add data sampling - limit to 1000 points
    const sampleData = dataLength > 1000 ? data?.filter((_, i) => i % Math.ceil(dataLength / 1000) === 0) : data

    worker.postMessage({ graph1: sampleData, graph2: null, tzOffset: offset })
    worker.onmessage = (e: MessageEvent) => {
      setLineData(e.data)
      dispatch(changeChartIsRendering(false))
    }
    return () => {
      worker.terminate()
    }
  }, [response])

  // Replace the useCallback with a simpler handler
  const handleChartUpdate = (chart: any) => {
    debouncedChartUpdate(chart, setChartArea)
  }

  // Memoize final chart options
  const finalChartOptions = useMemo(() => {
    if (!chartDataAndOptions) return null
    return {
      ...(chartDataAndOptions.chartOptions as any),
      animation: {
        onComplete: (chart: any) => handleChartUpdate(chart.chart),
      },
    }
  }, [chartDataAndOptions])

  const trendLineProps = useMemo(() => {
    if (!response?.trend || !chartArea || !showTrendLine) return null

    // Calculate maximum possible angle based on chart dimensions
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    const maxPossibleAngle = Math.atan2(chartHeight, chartWidth) * (180 / Math.PI);
    
    // Use 80% of the maximum possible angle to ensure some padding
    const safeMaxAngle = maxPossibleAngle * 0.8;

    // Calculate normalized trend value using logarithmic scale
    const MAX_TREND = 20; // Maximum trend value (20%)
    const trendMagnitude = Math.min(
      Math.log10(Math.abs(response.trend) + 1) / Math.log10(MAX_TREND + 1),
      1
    );

    // For negative trends, we want the angle to be 180 + the calculated angle
    // This will make it point downward
    const baseAngle = trendMagnitude * safeMaxAngle;
    const angle = response.trend > 0 ? baseAngle : 180 + baseAngle;

    return {
      angle,
      isPositive: (response?.trend ?? 0) > 0,
      color: (response?.trend ?? 0) <= 0 ? '#118F1E' : '#E11D48',
      chartArea,
    }
  }, [response?.trend, chartArea, showTrendLine])

  useEffect(() => {
    return () => {
      dispatch(changeChartIsRendering(false))
    }
  }, [])

  return (
    <ChartWrapper
      title={chartTitle}
      className="h-96 FuelEfficiency relative"
      tooltipId="FuelEfficiency"
      tooltipText="Chart colors represent the fuel efficiency trend for selected time period: Green indicates an improvement in fuel efficiency, while Red signifies a decline."
    >
      {loading || !chartDataAndOptions ? (
        <Loader />
      ) : !dataAvailable ? (
        <NoData
          type="warning"
          title="No data available for selected period"
          text="Please, try different date range."
        />
      ) : (
        <div className="relative w-full h-full">
          {trendLineProps && <TrendLine {...trendLineProps} />}
          <div className="relative z-10 w-full h-full">
            <Chart
              options={finalChartOptions}
              type="line"
              data={chartDataAndOptions.chartData}
            />
          </div>
        </div>
      )}
    </ChartWrapper>
  )
}

export default FuelEfficiency
