import TripMap from './TripMap'
import DoughnutChart, { DoughnutChartData } from 'components/chart/DoughnutChart'
import { useGetMissedSavingsBarChartQuery, useGetPerformanceBarChartInRangeQuery } from 'store/apis/totalsGraphApi'
import { VesselData } from 'interfaces/vessel'
import { useMemo } from 'react'
import { chartColorCodes } from 'shared/chart/chartColorCodes'

interface Props {
  vessel: VesselData
  startTime: number
  endTime: number
  offset: number
}

const centerTextPlugin = {
  id: 'centerText',
  beforeDraw(chart: any) {
    const { width } = chart
    const { height } = chart
    const { ctx } = chart

    const chartData = chart.config._config.data.datasets[0].data
    const smallestValue = Math.min(...chartData)

    ctx.restore()

    const valueFontSize = Math.min(height / 120, width / 120)
    const textFontSize = Math.min(height / 260, width / 260)

    ctx.textBaseline = 'middle'
    ctx.textAlign = 'center'

    const valueText = `${Number.isFinite(smallestValue) ? smallestValue : 0} t`
    ctx.font = `bold ${valueFontSize}em sans-serif`
    ctx.fillText(valueText, width / 2, height / 2 - height / 6)

    const savingsText = 'Missed Savings'
    ctx.font = `${textFontSize}em sans-serif`
    ctx.fillText(savingsText, width / 2, height / 2 - height / 18)

    ctx.save()
  },
}

function TripMapSection({ vessel, startTime, endTime, offset }: Props) {
  const {
    data: performanceData,
    isFetching: isPerformanceFetching,
    error: performanceError,
  } = useGetPerformanceBarChartInRangeQuery(
    {
      vesselId: vessel.id,
      mainEngineFlowMeterId: vessel.main_engines[0].flowMeter[0].sensorId,
      isSplit: vessel.main_engines[0].flowMeter[0].split,
      startTime: startTime,
      endTime: endTime,
      massFlowRateUpperThreshold: vessel.main_engines[0].massFlowRateUpperThreshold,
      massFlowRateLowerThreshold: vessel.main_engines[0].massFlowRateLowerThreshold,
    },
    { skip: startTime === 0 || endTime === 0 }
  )

  const {
    data: missedSavingsData,
    isFetching: isMissedSavingsFetching,
    error: missedSavingsError,
  } = useGetMissedSavingsBarChartQuery(
    {
      vesselId: vessel.id,
      mainEngineFlowMeterId: vessel.main_engines[0].flowMeter[0].sensorId,
      isSplit: false,
      startTime: startTime,
      endTime: endTime,
      massFlowRateUpperThreshold: vessel.main_engines[0].massFlowRateUpperThreshold,
      massFlowRateLowerThreshold: vessel.main_engines[0].massFlowRateLowerThreshold,
    },
    { skip: startTime === 0 || endTime === 0 }
  )

  const doughnutChartData: DoughnutChartData[] = useMemo(
    () => [
      {
        name: 'Below Limit',
        value: performanceData?.totalPercentage.belowLimit || 0,
        unit: '%',
        color: `rgb(${chartColorCodes.orange})`,
      },
      {
        name: 'In Range',
        value: performanceData?.totalPercentage.inRange || 0,
        unit: '%',
        color: `rgb(${chartColorCodes.green})`,
      },
      {
        name: 'Above Limit',
        value: performanceData?.totalPercentage.aboveLimit || 0,
        unit: '%',
        color: `rgb(${chartColorCodes.red})`,
      },
      {
        name: 'Maneuvering',
        value: performanceData?.totalPercentage.maneuvering || 0,
        unit: '%',
        color: `rgb(${chartColorCodes.darkGray})`,
      },
    ],
    [performanceData]
  )

  const missedSavingsChartData: DoughnutChartData[] = useMemo(() => {
    if (!missedSavingsData?.data?.length) return []

    const totalPerfectConsumption = Number(
      missedSavingsData.data.reduce((acc, curr) => acc + curr.value.perfectConsumption, 0).toFixed(1)
    )
    const totalMissedSavings = Number(
      missedSavingsData.data.reduce((acc, curr) => acc + curr.value.missedSavings, 0).toFixed(1)
    )

    return [
      {
        name: 'Perfect Consumption',
        value: totalPerfectConsumption,
        unit: 't',
        color: `rgb(${chartColorCodes.blue})`,
      },
      {
        name: 'Missed Savings',
        value: totalMissedSavings,
        unit: 't',
        color: `rgb(${chartColorCodes.gray})`,
      },
    ]
  }, [missedSavingsData])

  return (
    <div className="flex flex-row gap-4">
      <TripMap
        vessel={vessel}
        startTime={startTime}
        endTime={endTime}
        offset={offset}
      />
      <div className="flex flex-col">
        <DoughnutChart
          data={missedSavingsChartData}
          loading={isMissedSavingsFetching}
          title="M/E Fuel Consumption and Missed Savings"
          unitForLegend
          plugins={[centerTextPlugin]}
          dataAvailable={!!missedSavingsData?.data?.length}
        />

        <DoughnutChart
          data={doughnutChartData}
          title="Summary of Policy Adherence"
          noMarginBottom={true}
          loading={isPerformanceFetching}
          dataAvailable={!!performanceData?.totalPercentage}
          unitForLegend
          label="Percentage"
          tooltipText="Percentage distribution of time spent by the vessel at various power levels according to Company Policy"
          tooltipId="performance-tooltip"
        />
      </div>
    </div>
  )
}

export default TripMapSection
