import Button from 'components/Button'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useRunDataImportOrProcessingMutation } from 'store'

function ImportPage() {
  const { vesselId } = useParams()

  const [runDataImportOrProcessing, importStatus] = useRunDataImportOrProcessingMutation()

  function handleButtonClick() {
    runDataImportOrProcessing({ vesselId: vesselId || '' })
  }

  return (
    <div>
      <Button
        secondary
        onClick={handleButtonClick}
      >
        Import data
      </Button>
    </div>
  )
}

export default ImportPage
