import { ThunkAction } from '@reduxjs/toolkit'
import { GraphQueryArgs, TotalFuelEfficiencyResponse } from 'interfaces/graphApi'
import { RootState } from 'store'
import { inTimeGraphApi } from 'store/apis/inTimeGraphApi'

export const selectTotalFuelEfficiencyInTimeByArgs = (
  args: GraphQueryArgs
): ThunkAction<TotalFuelEfficiencyResponse | undefined, RootState, unknown, any> => {
  return (dispatch: any, getState: () => RootState) => {
    const state = getState()
    const queryState = inTimeGraphApi.endpoints.getTotalFuelEfficiencyInTime.select(args)(state)
    const data: TotalFuelEfficiencyResponse | undefined = queryState.data
    return data
  }
}
