import VesselIcon from 'assets/vessel_icon.png'
import { VesselData } from 'interfaces/vessel'
import { useAppSelector } from 'hooks/useReduxHooks'
import { RootState } from 'store'
import { anonymizeValue, shouldAnonymizeData } from 'utils/anonymizeData'
import Loader from './Loader'

interface Props {
  vessel: VesselData | null
  isLoading: boolean
  children?: React.ReactNode
}

function VesselInfo({ vessel, isLoading, children }: Props) {
  const user = useAppSelector((state: RootState) => state.auth.user)
  const shouldAnonymize = shouldAnonymizeData(user?.email)

  return (
    <>
      <div className="bg-white rounded-xl h-fit border border-grayOutlineColor w-full">
        {isLoading ? (
          <div className=" w-full h-full flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <>
            <div className="flex items-center h-24 w-full px-4 gap-4">
              <img
                src={VesselIcon}
                className="w-12 h-auto"
              ></img>

              <div className="flex flex-col">
                <p className="text-lg font-semibold"></p>
                <p className="text-lg font-semibold">{shouldAnonymize ? anonymizeValue(vessel?.name) : vessel?.name}</p>
                <p className="font-normal text-sm text-grayPlaceholder">
                  IMO {shouldAnonymize ? anonymizeValue(vessel?.imo) : vessel?.imo}
                </p>
              </div>

              {children && <div className="flex ml-auto">{children}</div>}
            </div>

            <div className="flex min-h-16 h-42 pb-1 border-t border-grayOutlineColor px-4 items-center gap-6">
              <div>
                <p className="lg:text-sm text-grayText">Vessel type</p>
                <p className="text-sm lg:text-lg">
                  {shouldAnonymize ? anonymizeValue(vessel?.vessel_type) : vessel?.vessel_type}
                </p>
              </div>

              <div className="bg-grayOutlineColor h-10 w-px" />

              <div>
                <p className="lg:text-sm text-grayText">DWT</p>
                <div className="text-sm lg:text-lg">{shouldAnonymize ? anonymizeValue(vessel?.dwt) : vessel?.dwt}</div>
              </div>

              <div className="bg-grayOutlineColor h-10 w-px" />

              <div>
                <p className="lg:text-sm text-grayText">Year built</p>
                <div className="text-sm lg:text-lg">
                  {shouldAnonymize ? anonymizeValue(vessel?.year_built) : vessel?.year_built}
                </div>
              </div>

              {vessel?.main_engines[0].propulsionType && (
                <>
                  <div className="bg-grayOutlineColor h-10 w-px" />
                  <div>
                    <p className="lg:text-sm text-grayText">Propulsion type</p>
                    <div className="text-sm lg:text-lg">{vessel?.main_engines[0].propulsionType}</div>
                  </div>
                </>
              )}

              {vessel?.main_engines[0].propulsionType && (
                <>
                  <div className="bg-grayOutlineColor h-10 w-px" />
                  <div>
                    <p className="lg:text-sm text-grayText">Company policy</p>
                    <div className="text-sm lg:text-lg">
                      <p className="flex items-center text-lush-meadow text-md">ECO Mode</p>
                    </div>
                  </div>
                </>
              )}

              {vessel?.main_engines[0].companyPolicyLowerThreshold &&
                vessel?.main_engines[0].companyPolicyUpperThreshold && (
                  <>
                    <div className="bg-grayOutlineColor h-10 w-px" />
                    <div>
                      <p className="lg:text-sm text-grayText">Main Engine Load, %</p>
                      <div className="flex flex-row text-sm lg:text-lg gap-2">
                        {shouldAnonymize
                          ? `${anonymizeValue(vessel?.main_engines[0].companyPolicyLowerThreshold)}% - ${anonymizeValue(vessel?.main_engines[0].companyPolicyUpperThreshold)}%`
                          : `${vessel?.main_engines[0].companyPolicyLowerThreshold}% - ${vessel?.main_engines[0].companyPolicyUpperThreshold}%`}
                        <p className="flex items-center text-lush-meadow text-sm">ECO Mode</p>
                      </div>
                    </div>
                  </>
                )}

              {vessel?.main_engines[0].powerLowerThreshold && vessel?.main_engines[0].powerUpperThreshold && (
                <>
                  <div className="bg-grayOutlineColor h-10 w-px" />
                  <div>
                    <p className="lg:text-sm text-grayText">Main Engine Power, kW</p>
                    <div className="flex flex-row text-sm lg:text-lg gap-2">
                      {shouldAnonymize
                        ? `${anonymizeValue(vessel?.main_engines[0].powerLowerThreshold)} - ${anonymizeValue(vessel?.main_engines[0].powerUpperThreshold)}`
                        : `${vessel?.main_engines[0].powerLowerThreshold} - ${vessel?.main_engines[0].powerUpperThreshold}`}
                      <p className="flex items-center text-lush-meadow text-sm">ECO Mode</p>
                    </div>
                  </div>
                </>
              )}

              {vessel?.main_engines[0].massFlowRateLowerThreshold &&
                vessel?.main_engines[0].massFlowRateUpperThreshold && (
                  <>
                    <div className="bg-grayOutlineColor h-10 w-px" />
                    <div>
                      <p className="lg:text-sm text-grayText">Main Engine Mass Flow Rate, kg/h</p>
                      <div className="flex flex-row text-sm lg:text-lg gap-2">
                        {shouldAnonymize
                          ? `${anonymizeValue(vessel?.main_engines[0].massFlowRateLowerThreshold)} - ${anonymizeValue(vessel?.main_engines[0].massFlowRateUpperThreshold)}`
                          : `${vessel?.main_engines[0].massFlowRateLowerThreshold} - ${vessel?.main_engines[0].massFlowRateUpperThreshold}`}
                        <p className="flex items-center text-lush-meadow text-sm">ECO Mode</p>
                      </div>
                    </div>
                  </>
                )}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default VesselInfo
