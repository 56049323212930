import CustomTable, {
  CustomTableContentRow,
  CustomTableHeaderCol,
  CustomTableSettings,
} from 'components/tables/CustomTable'
import { VesselData, VesselTripData } from 'interfaces/vessel'
import { getUTCDateString } from 'shared/date/getUTCDateString'
import { getUTCTimeString } from 'shared/date/getUTCTimeString'
import Checkmark from 'components/form/Checkmark'
import { useEffect, useState } from 'react'
import { anonymizeValue } from 'utils/anonymizeData'

interface AdjustedTripData extends VesselTripData {
  adjustedDepartureTime: number
  adjustedArrivalTime: number | null
}

interface Props {
  vessel: VesselData
  trips: VesselTripData[]
  selectedTrips: VesselTripData[]
  onTripCheck: (id: string) => void
  isFetching: boolean
  offset: number
  shouldAnonymize?: boolean
}

function PastTrips({ vessel, trips, selectedTrips, onTripCheck, isFetching, offset, shouldAnonymize = false }: Props) {
  const [adjustedTrips, setAdjustedTrips] = useState<AdjustedTripData[]>([])

  useEffect(() => {
    if (!trips) return

    const adjusted = trips.map((trip) => ({
      ...trip,
      adjustedDepartureTime: trip.departureTime + offset * 3600 * 1000,
      adjustedArrivalTime: trip.arrivalTime ? trip.arrivalTime + offset * 3600 * 1000 : null,
    }))

    setAdjustedTrips(adjusted)
  }, [trips, offset])

  const tableHeaderData: CustomTableHeaderCol[] = [
    {
      id: 'check',
      content: (
        <div className="min-w-4">
          <Checkmark disabled />
        </div>
      ),
    },
    {
      id: 'status',
      content: <p>Status</p>,
    },
    {
      id: 'departurePort',
      content: <p>Departure port</p>,
    },
    {
      id: 'arrivalPort',
      content: <p>Arrival port</p>,
    },
    {
      id: 'departureTime',
      content: <p>Departure time</p>,
    },
    {
      id: 'arrivalTime',
      content: <p>Arrival time</p>,
    },
    {
      id: 'duration',
      content: <p>Trip duration</p>,
    },
  ]

  const tableRowData: CustomTableContentRow[] =
    adjustedTrips?.map((trip, index) => {
      const departure = `${getUTCDateString(new Date(trip.adjustedDepartureTime))}, ${getUTCTimeString(new Date(trip.adjustedDepartureTime))}`
      const arrival = trip.adjustedArrivalTime
        ? `${getUTCDateString(new Date(trip.adjustedArrivalTime))}, ${getUTCTimeString(new Date(trip.adjustedArrivalTime))}`
        : 'N/A'
      const durationInMilliseconds = trip.arrivalTime ? trip.arrivalTime - trip.departureTime : 0
      const durationDays = Math.floor(durationInMilliseconds / (1000 * 60 * 60 * 24))
      const durationHours = Math.floor((durationInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const durationMinutes = Math.floor((durationInMilliseconds % (1000 * 60 * 60)) / (1000 * 60))
      const duration =
        durationDays > 0
          ? `${durationDays}d ${durationHours}h ${durationMinutes}min`
          : `${durationHours}h ${durationMinutes}min`
      const status = trip.eta ? 'inProgress' : 'completed'

      return {
        id: trip.departureTime.toString(),
        onRowClick: () => onTripCheck(trip.id),
        haystack: [
          shouldAnonymize ? anonymizeValue(trip.departurePort) : trip.departurePort?.toLowerCase(),
          shouldAnonymize ? anonymizeValue(trip.destinationPort) : trip.destinationPort?.toLowerCase(),
          departure.toLowerCase(),
          arrival.toLowerCase(),
        ],
        cols: [
          {
            id: 'check',
            value: '',
            content: <Checkmark checked={isChecked(trip.id)} />,
          },
          {
            id: 'status',
            value: '',
            content: <div>{status === 'completed' ? 'Finished' : 'In progress'}</div>,
          },
          {
            id: 'departurePort',
            value: trip.departurePort,
            content: <div>{shouldAnonymize ? anonymizeValue(trip.departurePort) : trip.departurePort}</div>,
          },
          {
            id: 'arrivalPort',
            value: trip.destinationPort,
            content: <div>{shouldAnonymize ? anonymizeValue(trip.destinationPort) : trip.destinationPort}</div>,
          },
          {
            id: 'departureTime',
            value: trip.departureTime,
            content: <div>{departure}</div>,
          },
          {
            id: 'arrivalTime',
            value: trip.arrivalTime || 0,
            content: <div>{arrival}</div>,
          },
          {
            id: 'duration',
            value: durationInMilliseconds,
            content: <p className="px-2 py-2">{trip.arrivalTime ? duration : 'N/A'}</p>,
          },
        ],
      }
    }) || []

  const tripsVerb = trips?.length === 1 ? 'trip' : 'trips'
  const tableTextWithTrips = `${shouldAnonymize ? anonymizeValue(vessel?.name) : vessel?.name} had ${trips?.length || 0} ${tripsVerb} during the selected period.`
  const tableTextNoTrips = `No trips registered for the selected period.`

  const columnClassNames = [
    'w-12 2xl:w-16',
    'min-w-32',
    'flex-1 min-w-36',
    'flex-1 min-w-36',
    'flex-1 min-w-40',
    'flex-1 min-w-40',
    'flex-1 min-w-40',
  ]

  const tableSettings: CustomTableSettings = {
    heading: 'Past trips',
    text: trips?.length ? tableTextWithTrips : tableTextNoTrips,
    horizontallyScrollable: true,
    contentClassName: 'max-h-96',
    columnExtraClassNames: columnClassNames,
    searchable: true,
    defaultViewMode: 'list',
    showViewModeSelector: false,
  }

  function isChecked(id: string) {
    return selectedTrips?.find((trip) => trip.id === id) ? true : false
  }

  return (
    <div>
      <CustomTable
        settings={tableSettings}
        headerData={tableHeaderData}
        rowData={tableRowData}
        isLoading={isFetching}
      />
    </div>
  )
}
export default PastTrips
