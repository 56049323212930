import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import ChartWrapper from './ChartWrapper'
import Loader from 'components/Loader'
import NoData from 'components/utility/NoData'

ChartJS.register(ArcElement, Tooltip, Legend)
ChartJS.defaults.font.family = 'Lato'
ChartJS.defaults.font.size = 14

export interface DoughnutChartData {
  name: string
  value: number
  unit: string
  color: string
}

interface Props {
  data: DoughnutChartData[]
  title: string
  loading: boolean
  dataAvailable?: boolean
  tooltipText?: string
  tooltipId?: string
  className?: string
  unitForLegend?: true
  plugins?: any[]
  label?: string
  noMarginBottom?: boolean
}

function DoughnutChart({
  data,
  title,
  loading,
  dataAvailable = true,
  tooltipText,
  tooltipId,
  className,
  unitForLegend,
  plugins = [],
  label = 'Amount in tons',
  noMarginBottom,
}: Props) {
  const chartData = {
    labels: [...data.map((item) => `${item.name} (${item.value}${item.unit})`)],
    datasets: [
      {
        data: [...data.map((item) => item.value)],
        backgroundColor: [...data.map((item) => item.color)],
        label: label,
        borderWidth: 0,
      },
    ],
  }

  const chartOptions = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom' as const,
        align: 'center' as const,
        labels: {
          usePointStyle: true,
          boxWidth: 6,
          padding: 15,
          textAlign: 'center' as const,
          pointStyleWidth: 20,
          useBorderRadius: true,
          borderRadius: 1,
          boxPadding: 1,
          generateLabels: (chart: any) => {
            const data = chart.data
            return data.labels.map((label: string, index: number) => ({
              text: label,
              fillStyle: data.datasets[0].backgroundColor[index],
              strokeStyle: data.datasets[0].backgroundColor[index],
              pointStyle: 'circle',
              index,
            }))
          },
        },
      },
    },
    cutout: '70%',
    maintainAspectRatio: false,
  }

  return (
    <ChartWrapper
      title={title}
      tooltipText={tooltipText}
      tooltipId={tooltipId}
      className={className}
      noMarginBottom={noMarginBottom}
    >
      {loading ? (
        <Loader />
      ) : !dataAvailable ? (
        <NoData
          type="warning"
          title="No data available for selected period"
          text="Please, try different date range."
        />
      ) : (
        <div className="w-full h-full flex flex-col items-center justify-center">
          <div
            className="w-full flex-1 flex items-center justify-center"
            style={{ minHeight: '300px' }}
          >
            <div className="w-full h-full">
              <Doughnut
                data={chartData}
                options={chartOptions}
                plugins={plugins}
              />
            </div>
          </div>
        </div>
      )}
    </ChartWrapper>
  )
}

export default DoughnutChart
