import { useEffect, useState } from 'react'
import ReusableInputField from '../components/Input'
import { useNavigate } from 'react-router-dom'
import { useCreateUserMutation } from 'store'
import Button from 'components/Button'
import Loader from 'components/Loader'

function CreateNewUser() {
  const navigate = useNavigate()
  const [name, setName] = useState<string>('')
  const [surname, setSurname] = useState<string>('')
  const [email, setEmail] = useState<string>('')

  const [createUser, createUserStatus] = useCreateUserMutation()

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    if (name !== '' && surname !== '' && email !== '') {
      createUser({ name, surname, email })
    }
  }

  useEffect(() => {
    if (createUserStatus.isSuccess) {
      navigate('/users')
    }
  }, [createUserStatus.isSuccess])

  useEffect(() => {
    if (createUserStatus.isError) {
      console.error('User creation failed:', createUserStatus.error)
    }
  }, [createUserStatus.isError])

  return (
    <div className="pt-16 pb-12 bg-primary not-phone:h-full flex flex-1 flex-col justify-center overflow-x-hidden">
      <div>
        {createUserStatus.isLoading ? (
          <div className="w-full flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <div className="flex justify-center mt-4">
            <div className="bg-white px-8 py-8 border border-grayOutlineColor rounded-xl w-[508px]">
              <div className="flex font-semibold mb-6 text-2xl lg:text-4xl">Create new user</div>

              <p className="text-sm font-base text-[#373F52] mb-8">
                Verification email will be sent to verify the account and get temporary password.
              </p>

              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <ReusableInputField
                    label="Name"
                    type="text"
                    value={name}
                    onChange={(value) => setName(value)}
                    placeholder="Enter name"
                    height="48px"
                    required
                    className="text-lg"
                  />
                </div>

                <div className="mb-4">
                  <ReusableInputField
                    label="Surname"
                    type="text"
                    value={surname}
                    onChange={(value) => setSurname(value)}
                    placeholder="Enter surname"
                    height="48px"
                    required
                    className="text-lg"
                  />
                </div>

                <div className="mb-4">
                  <ReusableInputField
                    label="Email"
                    type="email"
                    value={email}
                    onChange={(value) => setEmail(value)}
                    placeholder="Enter email"
                    height="48px"
                    required
                    className="text-lg"
                  />
                </div>

                <div className="text-sm text-left">
                  {createUserStatus.error?.constructor === String && (
                    <p className="text-[#E1341E] mb-4 ml-6">{createUserStatus.error}</p>
                  )}
                </div>

                <div className="flex flex-col gap-4 mt-8">
                  <Button
                    secondary
                    semibold
                    large
                    fullWidth
                  >
                    Create new user
                  </Button>
                  <Button
                    type="button"
                    onClick={() => navigate(-1)}
                    primary
                    semibold
                    large
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default CreateNewUser
