import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js'
import ChartWrapper from './ChartWrapper'
import Loader from 'components/Loader'
import NoData from 'components/utility/NoData'
import { getTimestampWithOffset } from 'shared/date/getTimestampWithOffset'
import { getUTCDateString } from 'shared/date/getUTCDateString'
import { BarChartData } from 'interfaces/graphApi'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
ChartJS.defaults.font.family = 'Lato'
ChartJS.defaults.font.size = 14

export interface BarChartDataWithExtras extends BarChartData {
  color: string
  title: string
}

interface Props {
  data: BarChartDataWithExtras[]
  title: string
  loading: boolean
  offset: number
  dataAvailable?: boolean
  tooltipText?: string
  tooltipId?: string
  className?: string
  maxY?: number
  yAxisText?: string
  hideYAxisLabels?: boolean
  yAxisTitlePadding?: number
  showTime?: boolean
}

function BarChart({
  data,
  title,
  loading,
  offset,
  dataAvailable = true,
  tooltipText,
  tooltipId,
  className,
  maxY,
  yAxisText,
  hideYAxisLabels = false,
  yAxisTitlePadding,
  showTime = false,
}: Props) {
  const shouldShowTime =
    showTime &&
    data?.[0]?.data?.length > 0 &&
    (() => {
      const firstTimestamp = parseInt(data[0].data[0].category)
      const lastTimestamp = parseInt(data[0].data[data[0].data.length - 1].category)
      const diffInDays = (lastTimestamp - firstTimestamp) / (1000 * 60 * 60 * 24)
      return diffInDays <= 4
    })()

  let chartData = {
    labels:
      data?.[0]?.data.map((item: any) => {
        const timestamp = parseInt(item.category)
        const date = new Date(getTimestampWithOffset(timestamp, offset))
        if (shouldShowTime) {
          const time = date.toLocaleString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
          })
          const dateStr = date.toLocaleString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })
          return [time, dateStr]
        }
        return getUTCDateString(date)
      }) || [],
    datasets: [
      ...(data?.map((dataset: any) => ({
        label: dataset.title,
        data: dataset.data.map((item: any) => ({ x: new Date(parseInt(item.category)), y: item.value })),
        backgroundColor: dataset.color,
        maxBarThickness: 100,
      })) || []),
    ],
  } as any

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,

    interaction: {
      intersect: false,
      mode: 'index' as const,
    },

    plugins: {
      legend: {
        position: 'bottom' as const,

        labels: {
          boxWidth: 12,
          boxHeight: 12,
          usePointStyle: true,
          pointStyle: 'rectRounded',
          padding: 10,
          font: {
            size: 16,
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          callback: function (val: any, index: number) {
            // Return both lines of the label
            return chartData.labels[index]
          },
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: yAxisText || data?.[0]?.title,
          font: {
            size: 16,
          },
          ...(yAxisTitlePadding && { padding: { bottom: yAxisTitlePadding } }),
        },
        max: maxY,
        ticks: {
          display: !hideYAxisLabels,
        },
      },
    },
    elements: {
      bar: {
        borderRadius: 4,
      },
    },
  } as any

  return (
    <ChartWrapper
      title={title}
      tooltipText={tooltipText}
      tooltipId={tooltipId}
      className={className}
    >
      {loading ? (
        <Loader />
      ) : !dataAvailable ? (
        <NoData
          type="warning"
          title="No data available for selected period"
          text="Please, try different date range."
        />
      ) : (
        <Bar
          options={chartOptions}
          data={chartData}
        ></Bar>
      )}
    </ChartWrapper>
  )
}

export default BarChart
