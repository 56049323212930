const workerFunction = function () {
  /* eslint-disable-next-line no-restricted-globals */
  self.onmessage = (event: MessageEvent) => {
    const { graphData, tzOffset } = event.data

    const xlabels: any[] = []
    const timestamps: any[] = []
    let dataset1Values: number[] = []
    let dataset2Values: number[] = []
    let dataset3Values: number[] = []

    for (let i = 0; i < graphData?.length; i++) {
      const item = graphData[i]
      const date = new Date(item.timestamp)
      date.setUTCHours(date.getUTCHours() + tzOffset)
      xlabels.push(date.toUTCString())
      timestamps.push(date.getTime() / 1000)
      dataset1Values.push(item.line)
      dataset2Values.push(item.upperBound)
      dataset3Values.push(item.lowerBound)
    }

    postMessage({ xlabels, timestamps, dataset1Values, dataset2Values, dataset3Values })
  }
}

// This stringifies the whole function
let codeToString = workerFunction.toString()
// This brings out the code in the bracket in string
let mainCode = codeToString.substring(codeToString.indexOf('{') + 1, codeToString.lastIndexOf('}'))
// convert the code into a raw data
let blob = new Blob([mainCode], { type: 'application/javascript' })
// A url is made out of the blob object and we're good to go
let lineRangeChartWorker = URL.createObjectURL(blob)

export default lineRangeChartWorker
