import { createSlice } from '@reduxjs/toolkit'

interface NewAnalyticsState {
  numberOfTripsToShow: number
}

const initialState: NewAnalyticsState = {
  numberOfTripsToShow: 3,
}

const newAnalyticsSlice = createSlice({
  name: 'newAnalytics',
  initialState,
  reducers: {
    changeNumberOfTripsToShow(state, action) {
      state.numberOfTripsToShow = action.payload
    },
  },
})

export const newAnalyticsReducer = newAnalyticsSlice.reducer
export const { changeNumberOfTripsToShow } = newAnalyticsSlice.actions
