import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ITimezoneOption } from 'react-timezone-select'
import { VesselData } from 'interfaces/vessel'

type ViewMode = 'list' | 'grid'

interface AppState {
  chartIsRendering: boolean
  selectedTimezone: ITimezoneOption
  graphDataStatus: {
    graph1IsFetching: boolean
    graph2IsFetching: boolean
  }
  lineChartTickCount: number
  selectedVessel: VesselData | null
  dateRange: {
    startTime: number
    endTime: number
  }
  viewMode: ViewMode
  termsAccepted: boolean
}

const initialState: AppState = {
  chartIsRendering: false,
  selectedTimezone: {
    abbrev: 'GMT',
    label: '(GMT+0:00) UTC',
    value: 'Etc/GMT',
    offset: 0,
    altName: 'British Standard Time',
  },
  graphDataStatus: {
    graph1IsFetching: false,
    graph2IsFetching: false,
  },
  lineChartTickCount: 10,
  selectedVessel: null,
  dateRange: {
    startTime: 0,
    endTime: 0,
  },
  viewMode: 'list',
  termsAccepted: true,
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    changeGraphIsFetching(state, action) {
      const { graphNr, isFetching } = action.payload
      if (graphNr === 1) {
        state.graphDataStatus.graph1IsFetching = isFetching
      }
      if (graphNr === 2) {
        state.graphDataStatus.graph2IsFetching = isFetching
      }
    },
    changeChartIsRendering(state, action) {
      state.chartIsRendering = action.payload
    },
    changeTimezoneOffset(state, action) {
      state.selectedTimezone = action.payload
    },
    changeLineChartTickCount(state, action) {
      state.lineChartTickCount = action.payload
    },
    changeSelectedVessel(state, action) {
      state.selectedVessel = action.payload
    },
    changeDateRange(state, action) {
      state.dateRange = action.payload
    },
    setViewMode: (state, action: PayloadAction<ViewMode>) => {
      state.viewMode = action.payload
    },
    setTermsAccepted: (state, action: PayloadAction<boolean>) => {
      state.termsAccepted = action.payload
    },
  },
})

export const appReducer = appSlice.reducer
export const {
  changeGraphIsFetching,
  changeChartIsRendering,
  changeTimezoneOffset,
  changeLineChartTickCount,
  changeSelectedVessel,
  changeDateRange,
  setViewMode,
  setTermsAccepted,
} = appSlice.actions
