/* 
A reusable table component with many features:
- Both table header cols and row cols can accept any type of content, including components or blocks of different nodes with their own props and functions
- Rows can be sorted by any column, by providing value prop for each column in the row
- Rows can be filtered by providing searchable strings for each row (haystack)
- Rows accept onRowClick prop as a function, which can be called when the row is clicked
- Table also accepts the optional settings prop which allows configuration options
- Maximum table height can be configured by adding classes through the contentClassName setting
- Table content will automatically become scrollable if the content overflows configured maximum content height
- Column widths can be configured via the columnExtraClassNames setting
- Table rows can be styled with the rowExtraClassName setting
- Sortable columns can be configured by providing an array of column ids in the sortableColumns setting
- The first item in the sortableColumns array will be the default column to sort by
*/

import React, { useRef, useState } from 'react'
import classNames from 'classnames'
import ReusableInputField from 'components/Input'
import Loader from 'components/Loader'
import { ReactComponent as SortAscIcon } from 'assets/icons/SortAscending.svg'
import { ReactComponent as SortDescIcon } from 'assets/icons/SortDescending.svg'
import { ReactComponent as CaretIcon } from 'assets/icons/caretDown.svg'
import GridViewIcon from 'assets/icons/grids.png'
import ListViewIcon from 'assets/icons/list.png'
import useClickAway from 'hooks/useClickAway'
import GridView from '../../components/GridView'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { setViewMode } from 'store/slices/appSlice'

export interface Props {
  settings?: CustomTableSettings
  headerData: any[]
  rowData: any[] | undefined
  isLoading?: boolean
}

export interface CustomTableSettings {
  heading?: string
  text?: string
  searchable?: boolean | undefined
  sortable?: boolean
  smallText?: boolean
  columnExtraClassNames?: string[]
  horizontallyScrollable?: boolean
  contentClassName?: string
  rowExtraClassName?: string
  sortableColumns?: string[]
  expandableRows?: boolean
  defaultSort?: {
    columnId: string
    value: string | number
    priority: 'high' | 'low'
  }
  showViewModeSelector?: boolean
  gridViewComponent?: React.ComponentType<any>
  defaultViewMode?: 'list' | 'grid'
}

export interface CustomTableHeaderCol {
  id: string
  content: React.ReactNode
}

interface CustomTableContentCol {
  id: string
  value: string | number
  content: React.ReactNode
}

export interface CustomTableContentRow {
  id: string
  haystack?: string[]
  onRowClick?: any
  cols: CustomTableContentCol[]
  expandableContent?: React.ReactNode
}

interface SortState {
  columnIndex: number
  isAscending: boolean
  isDefaultSorting: boolean
}

export default function CustomTable({ settings, headerData, rowData, isLoading }: Props) {
  const [searchQuery, setSearchQuery] = useState('')
  const [orderByColumn, setOrderByColumn] = useState(getInitialOrderByColumn())
  const [isAscending, setIsAscending] = useState(true)
  const [isDefaultSorting, setIsDefaultSorting] = useState(true)
  const [activeRowIndex, setActiveRowIndex] = useState<number | null>(null)
  const tableRef = useRef<HTMLDivElement>(null)
  const viewMode = useAppSelector((state) => state.app.viewMode ?? settings?.defaultViewMode ?? 'list')
  const dispatch = useAppDispatch()

  let colClassNames: string[] = []
  if (!settings?.columnExtraClassNames) {
    colClassNames = Array(headerData.length).fill('flex-1')
  } else {
    colClassNames = settings.columnExtraClassNames
  }

  const handleSearchChange = (value: string) => {
    setSearchQuery(value.toLowerCase())
  }

  function toggleSort() {
    setIsAscending(!isAscending)
  }

  function handleSortChange(index: number) {
    if (!settings?.sortable) return
    setOrderByColumn(index)
    setIsDefaultSorting(false)
    if (orderByColumn === index) {
      toggleSort()
    }
  }

  function getColIndex(id: string) {
    return headerData.findIndex((col) => col.id === id)
  }

  function getInitialOrderByColumn() {
    if (settings?.sortable && settings?.sortableColumns) {
      return getColIndex(settings.sortableColumns[0])
    }
    return 0
  }

  function isColSortable(id: string) {
    if (!settings?.sortable) return false
    if (settings?.sortable && settings?.sortableColumns) {
      return settings.sortableColumns.includes(id)
    }
    return true
  }

  const handleRowClick = (index: number) => {
    setActiveRowIndex((prevIndex) => (prevIndex === index ? null : index))
  }

  function deselectActiveRow() {
    setActiveRowIndex(null)
  }

  useClickAway(tableRef, deselectActiveRow)

  const handleViewModeChange = (mode: 'list' | 'grid') => {
    dispatch(setViewMode(mode))
  }

  const TableHeader = ({ headerData }: { headerData: CustomTableHeaderCol[] }) => {
    return (
      <div className="sticky top-0 text-smoky-steel w-full h-10 z-10 border-b border-grayOutlineColor">
        <div className="flex h-full items-center">
          {headerData.map((col: CustomTableHeaderCol, index: number) => (
            <div
              key={col.id}
              className={classNames(
                'bg-whisper-gray flex items-center h-full gap-2 pr-3',
                colClassNames && colClassNames[index],
                {
                  'cursor-pointer': settings?.sortable && isColSortable(col.id),
                  'pl-4 2xl:pl-6': index === 0,
                  'border-b border-grayOutlineColor': isLoading,
                }
              )}
              onClick={() => isColSortable(col.id) && handleSortChange(index)}
            >
              {col.content}
              {settings?.sortable &&
                orderByColumn === index &&
                (isAscending ? (
                  <SortAscIcon className="w-auto h-3 fill-silver-mist" />
                ) : (
                  <SortDescIcon className="w-auto h-3 fill-silver-mist" />
                ))}
            </div>
          ))}
          {settings?.expandableRows && (
            <div
              className={classNames('bg-whisper-gray flex items-center h-full gap-2 pr-3 flex-1', {
                'border-b border-grayOutlineColor': isLoading,
              })}
            ></div>
          )}
        </div>
      </div>
    )
  }

  const ExpandableContainer = ({ children, isOpen }: { children: React.ReactNode; isOpen: boolean }) => {
    const expandableRef = useRef<HTMLDivElement>(null)
    return (
      <div
        className={classNames('overflow-hidden pl-4 2xl:pl-6 pr-4 2xl:pr-6', {
          'border-t border-grayOutlineColor': isOpen,
        })}
      >
        <div
          ref={expandableRef}
          style={isOpen ? { height: expandableRef.current?.scrollHeight } : { height: '0px' }}
        >
          {children}
        </div>
      </div>
    )
  }

  const TableRows = ({ rowData }: { rowData: CustomTableContentRow[] | undefined }) => {
    let allRows = rowData
    if (settings?.searchable) {
      allRows = rowData?.filter((row: CustomTableContentRow) => {
        let matchFound = false
        row.haystack?.forEach((haystack) => {
          if (haystack.includes(searchQuery)) {
            matchFound = true
          }
        })
        return matchFound
      })
    }

    const sortedRows = settings?.sortable
      ? allRows?.sort((a: any, b: any) => {
          // Only apply default sort if we haven't sorted by any column yet
          if (isDefaultSorting && settings?.defaultSort) {
            const aDefaultCol = a.cols.find((col: any) => col.id === settings.defaultSort?.columnId)
            const bDefaultCol = b.cols.find((col: any) => col.id === settings.defaultSort?.columnId)

            if (
              aDefaultCol?.value === settings.defaultSort.value &&
              bDefaultCol?.value !== settings.defaultSort.value
            ) {
              return settings.defaultSort.priority === 'high' ? -1 : 1
            }
            if (
              aDefaultCol?.value !== settings.defaultSort.value &&
              bDefaultCol?.value === settings.defaultSort.value
            ) {
              return settings.defaultSort.priority === 'high' ? 1 : -1
            }
          }

          // Regular column sorting
          const aVal = a.cols[orderByColumn].value
          const bVal = b.cols[orderByColumn].value

          if (aVal < bVal) {
            return isAscending ? -1 : 1
          }
          if (aVal > bVal) {
            return isAscending ? 1 : -1
          }
          return 0
        })
      : allRows

    return (
      <div
        className={classNames(
          'flex flex-col align-stretch flex-1 w-full',
          settings?.contentClassName && settings.contentClassName
        )}
      >
        {sortedRows &&
          sortedRows.map((row: CustomTableContentRow, rowIndex) => (
            <div
              key={row.id}
              onClick={() => handleRowClick(rowIndex)}
            >
              <div
                className={classNames('flex w-full', {
                  'cursor-pointer': row.onRowClick || row.expandableContent,
                })}
                onClick={row.onRowClick && row.onRowClick}
              >
                <div className={classNames('flex w-full')}>
                  {row.cols.map((col: CustomTableContentCol, colIndex: number) => {
                    return (
                      <div
                        key={col.id}
                        className={classNames(
                          'flex flex-col justify-center border-t border-grayOutlineColor',
                          settings?.rowExtraClassName ? settings.rowExtraClassName : 'min-h-8 py-[0.6rem]',
                          colClassNames && colClassNames[colIndex],
                          {
                            'pl-4 2xl:pl-6': colIndex === 0,
                          }
                        )}
                      >
                        <div
                          key={col.id}
                          className={classNames('flex items-center gap-2 pr-3')}
                        >
                          {col.content}
                        </div>
                      </div>
                    )
                  })}
                  {!!row.expandableContent && (
                    <div
                      className={classNames(
                        'flex flex-1 flex-col justify-center  border-t border-grayOutlineColor',
                        settings?.rowExtraClassName ? settings.rowExtraClassName : 'min-h-8 py-[0.6rem]'
                      )}
                    >
                      <div className={classNames('flex items-center gap-2 pr-3')}>
                        <div className="w-full mr-5 flex justify-end">
                          <CaretIcon
                            className={classNames('w-6 h-6 fill-silver-mist', {
                              'rotate-180': activeRowIndex === rowIndex,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {!!row.expandableContent && (
                <ExpandableContainer isOpen={activeRowIndex === rowIndex}>{row.expandableContent}</ExpandableContainer>
              )}
            </div>
          ))}
      </div>
    )
  }

  const currentSort: SortState = {
    columnIndex: orderByColumn,
    isAscending,
    isDefaultSorting,
  }

  const getFilteredData = (data: CustomTableContentRow[] | undefined) => {
    if (!settings?.searchable || !searchQuery || !data) return data

    return data.filter((row: CustomTableContentRow) => {
      let matchFound = false
      row.haystack?.forEach((haystack) => {
        if (haystack.includes(searchQuery)) {
          matchFound = true
        }
      })
      return matchFound
    })
  }

  return (
    <div
      ref={tableRef}
      className={classNames('bg-white rounded-xl flex-grow border border-grayOutlineColor overflow-hidden', {
        'lg:text-sm xl:text-lg leading-5': !settings?.smallText,
        'text-[0.83rem] 2xl:text-lg leading-4': settings?.smallText,
      })}
    >
      <div className="flex justify-between w-full h-20 border-b border-grayOutlineColor px-4 2xl:px-6">
        {(!!settings?.heading || !!settings?.text) && (
          <div className="flex flex-col justify-center">
            {!!settings?.heading && <p className="font-semibold text-sm lg:text-lg leading-7">{settings?.heading}</p>}
            {settings?.text && (
              <p
                className={classNames(' text-smoky-steel leading-6', {
                  'lg:text-sm xl:text-lg': !settings?.smallText,
                  'text-[0.83rem] 2xl:text-lg': settings?.smallText,
                })}
              >
                {settings?.text}
              </p>
            )}
          </div>
        )}

        <div className="flex gap-4 justify-center items-center">
          {settings?.searchable && (
            <ReusableInputField
              placeholder="Search vessel"
              width="16rem"
              height="2.5rem"
              isSearchInput={true}
              value={searchQuery}
              onChange={handleSearchChange}
              className="text-sm lg:text-lg"
            />
          )}
          {settings?.showViewModeSelector && (
            <div className="flex items-center gap-2 border border-grayOutlineColor rounded-lg p-1">
              <button
                className={classNames('p-2 rounded-md transition-colors', {
                  'bg-whisper-gray': viewMode === 'list',
                })}
                onClick={() => handleViewModeChange('list')}
              >
                <img
                  src={ListViewIcon}
                  alt="List view"
                  className="w-4 h-4"
                />
              </button>
              <button
                className={classNames('p-2 rounded-md transition-colors', {
                  'bg-whisper-gray': viewMode === 'grid',
                })}
                onClick={() => handleViewModeChange('grid')}
              >
                <img
                  src={GridViewIcon}
                  alt="Grid view"
                  className="w-4 h-4"
                />
              </button>
            </div>
          )}
        </div>
      </div>

      {viewMode === 'list' || !settings?.gridViewComponent ? (
        <div className={classNames('relative', settings?.horizontallyScrollable && 'overflow-x-auto')}>
          <TableHeader headerData={headerData} />
          {isLoading ? (
            <div className="w-full flex justify-center mt-5 mb-5 items-center">
              <Loader />
            </div>
          ) : (
            <TableRows rowData={getFilteredData(rowData)} />
          )}
        </div>
      ) : (
        settings?.gridViewComponent &&
        React.createElement(settings.gridViewComponent, {
          data: getFilteredData(rowData),
          isLoading,
          sortState: currentSort,
        })
      )}
      <div className="h-20 border-t border-grayOutlineColor"></div>
    </div>
  )
}
