import BarChart, { BarChartDataWithExtras } from 'components/chart/BarChart'
import DoughnutChart, { DoughnutChartData } from 'components/chart/DoughnutChart'
import { BarChartData, MissedSavingsBarChartData } from 'interfaces/graphApi'
import { VesselData } from 'interfaces/vessel'
import { chartColorCodes } from 'shared/chart/chartColorCodes'
import { useGetMissedSavingsBarChartQuery } from 'store/apis/totalsGraphApi'
import { useEffect, useMemo } from 'react'

interface Props {
  vessel: VesselData
  startTime: number
  endTime: number
  offset: number
  tooltipText?: string
  tooltipId?: string
}

const centerTextPlugin = {
  id: 'centerText',
  beforeDraw(chart: any) {
    const { width } = chart
    const { height } = chart
    const { ctx } = chart

    const chartData = chart.config._config.data.datasets[0].data
    const smallestValue = Math.min(...chartData)

    ctx.restore()

    const valueFontSize = Math.min(height / 120, width / 120)
    const textFontSize = Math.min(height / 260, width / 260)

    ctx.textBaseline = 'middle'
    ctx.textAlign = 'center'

    const valueText = `${Number.isFinite(smallestValue) ? smallestValue : 0} t`
    ctx.font = `bold ${valueFontSize}em sans-serif`
    ctx.fillText(valueText, width / 2, height / 2 - height / 6)

    const savingsText = 'Missed Savings'
    ctx.font = `${textFontSize}em sans-serif`
    ctx.fillText(savingsText, width / 2, height / 2 - height / 18)

    ctx.save()
  },
}

function MissedSavings({ vessel, startTime, endTime, offset, tooltipText, tooltipId }: Props) {
  const {
    data: apiData,
    isFetching,
    error,
  } = useGetMissedSavingsBarChartQuery(
    {
      vesselId: vessel.id,
      mainEngineFlowMeterId: vessel.main_engines[0].flowMeter[0].sensorId,
      isSplit: false,
      startTime: startTime,
      endTime: endTime,
      massFlowRateUpperThreshold: vessel.main_engines[0].massFlowRateUpperThreshold,
      massFlowRateLowerThreshold: vessel.main_engines[0].massFlowRateLowerThreshold,
    },
    { skip: startTime === 0 || endTime === 0 }
  )

  const barChartData: BarChartDataWithExtras[] = useMemo(
    () =>
      apiData
        ? [
            {
              label: 'perfectConsumption',
              unitOfMeasurement: apiData.unitOfMeasurement,
              unitOfRange: apiData.unitOfRange,
              data: apiData.data.map((item) => ({
                category: item.category,
                value: item.value.perfectConsumption,
              })),
              color: `rgb(${chartColorCodes.blue})`,
              title: 'Perfect Consumption [mt]',
            },
            {
              label: 'missedSavings',
              unitOfMeasurement: apiData.unitOfMeasurement,
              unitOfRange: apiData.unitOfRange,
              data: apiData.data.map((item) => ({
                category: item.category,
                value: item.value.missedSavings,
              })),
              color: `rgb(${chartColorCodes.gray})`,
              title: 'Missed Savings [mt]',
            },
          ]
        : [],
    [apiData]
  )

  const doughnutChartData: DoughnutChartData[] = useMemo(() => {
    if (!apiData) return []

    const totalPerfectConsumption = Number(
      apiData.data.reduce((acc, curr) => acc + curr.value.perfectConsumption, 0).toFixed(1)
    )
    const totalMissedSavings = Number(apiData.data.reduce((acc, curr) => acc + curr.value.missedSavings, 0).toFixed(1))

    return [
      {
        name: 'Perfect Consumption',
        value: totalPerfectConsumption,
        unit: 't',
        color: `rgb(${chartColorCodes.blue})`,
      },
      {
        name: 'Missed Savings',
        value: totalMissedSavings,
        unit: 't',
        color: `rgb(${chartColorCodes.gray})`,
      },
    ]
  }, [apiData])

  const hasData = useMemo(() => {
    if (!apiData) return false
    const totalPerfectConsumption = apiData.data.reduce((acc, curr) => acc + curr.value.perfectConsumption, 0)
    const totalMissedSavings = apiData.data.reduce((acc, curr) => acc + curr.value.missedSavings, 0)
    return totalPerfectConsumption > 0 || totalMissedSavings > 0
  }, [apiData])

  return (
    <div className="flex gap-4">
      <div className="w-80 xl:w-96 3xl:w-[28rem]">
        <DoughnutChart
          data={doughnutChartData}
          loading={isFetching}
          title="M/E Fuel Consumption and Missed Savings"
          className="h-72"
          unitForLegend
          plugins={[centerTextPlugin]}
          dataAvailable={hasData}
          tooltipText={tooltipText}
          tooltipId={tooltipId}
        />
      </div>
      <BarChart
        data={barChartData}
        title="M/E Fuel Consumption and Missed Savings"
        loading={isFetching}
        offset={offset}
        className="h-72"
        dataAvailable={hasData}
        tooltipText={tooltipText}
        tooltipId={tooltipId}
        showTime={true}
      />
    </div>
  )
}

export default MissedSavings
