export const anonymizeValue = (value: string | number | undefined): string => {
  if (!value) return 'xxxxx'

  return value
    .toString()
    .split('')
    .map((char) => (char === ' ' ? ' ' : 'x'))
    .join('')
}

export const shouldAnonymizeData = (email: string | null | undefined): boolean => {
  return email === 'demo@gmail.com'
}
