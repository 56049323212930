import {
  Chart as ChartJS,
  LinearScale,
  LineElement,
  Legend,
  CategoryScale,
  Filler,
  Tooltip as ChartTooltip,
} from 'chart.js'
import ChartWrapper from 'components/chart/ChartWrapper'
import Loader from 'components/Loader'
import NoData from 'components/utility/NoData'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { Scales } from 'interfaces/chart'
import { RangeAreaGraphData } from 'interfaces/graphApi'
import { VesselData } from 'interfaces/vessel'
import { useEffect, useState } from 'react'
import { Chart } from 'react-chartjs-2'
import { chartColorCodes } from 'shared/chart/chartColorCodes'
import getLineChartOptions from 'shared/chart/getLineChartOptions'
import getLineChartScaleX from 'shared/chart/getLineChartScaleX'
import getLineChartScaleY from 'shared/chart/getLineChartScaleY'
import { changeChartIsRendering, RootState, useGetMassFlowRateRangeAreaQuery } from 'store'
import lineRangeChartWorker from 'workers/lineRangeChartWorker'

ChartJS.register(LinearScale, LineElement, CategoryScale, Legend, ChartTooltip, Filler)
ChartJS.defaults.font.family = 'Lato'
ChartJS.defaults.font.size = 14

interface Props {
  vessel: VesselData
  startTime: number
  endTime: number
  offset: number
  tooltipText?: string
  tooltipId?: string
}

function MassFlowRate({ vessel, startTime, endTime, offset, tooltipText, tooltipId }: Props) {
  const dispatch = useAppDispatch()
  const [chartData, setChartData] = useState<any>(null)
  const chartIsRendering = useAppSelector((state: RootState) => state.app.chartIsRendering)
  const lineChartTickCount = useAppSelector((state: RootState) => state.app.lineChartTickCount)
  const chartTitle = 'Mass flow rate [kg/h]'

  const { data, isFetching } = useGetMassFlowRateRangeAreaQuery({
    vesselId: vessel.id,
    flowMeterId: vessel.main_engines[0].flowMeter[0].sensorId,
    startTime: startTime,
    endTime: endTime,
    isSplit: true,
  })

  const dataAvailable = !!data && data.length > 0

  let chartDataAndOptions = null
  const vesselLastUpdated = vessel?.last_updated?._seconds || 0

  if (chartData) {
    const { xlabels, timestamps, dataset1Values, dataset2Values, dataset3Values } = chartData

    const datasets: any = []
    if (dataset1Values?.length) {
      const chartLabel = 'Avg'
      const color = `rgb(${chartColorCodes.green})`
      datasets.push({
        label: chartLabel,
        data: dataset1Values,
        borderColor: color,
        borderWidth: 2,
        fill: false,
        spanGaps: true,
        pointRadius: 0,
        segment: {
          borderColor: (context: any) => {
            const segmentTimestamp = timestamps[context.p1DataIndex]
            return segmentTimestamp > vesselLastUpdated ? 'transparent' : color
          },
        },
        tension: 0.2,
      })
    }
    if (dataset2Values?.length) {
      const chartLabel = 'Max'
      const color = `rgba(${chartColorCodes.green}, 0.1)`
      datasets.push({
        label: chartLabel,
        data: dataset2Values,
        borderColor: 'rgba(0,0,0,0)',
        backgroundColor: color,
        fill: '-1',
        spanGaps: true,
        pointRadius: 0,
        segment: {
          backgroundColor: (context: any) => {
            const segmentTimestamp = timestamps[context.p1DataIndex]
            return segmentTimestamp > vesselLastUpdated ? 'transparent' : color
          },
        },
        tension: 0.2,
      })
    }
    if (dataset3Values?.length) {
      const chartLabel = 'Min'
      const color = `rgba(${chartColorCodes.green}, 0.3)`
      datasets.push({
        label: chartLabel,
        data: dataset3Values,
        borderColor: 'rgba(0,0,0,0)',
        backgroundColor: color,
        fill: '-1',
        spanGaps: true,
        pointRadius: 0,
        segment: {
          backgroundColor: (context: any) => {
            const segmentTimestamp = timestamps[context.p1DataIndex]
            return segmentTimestamp > vesselLastUpdated ? 'transparent' : color
          },
        },
        tension: 0.2,
      })
    }

    const scales: Scales = {}
    if (xlabels?.length) {
      scales.x = getLineChartScaleX(xlabels, lineChartTickCount)
      if (dataset1Values?.length) {
        const chartLabel = chartTitle
        scales.y = getLineChartScaleY(chartLabel)
      }
    }

    chartDataAndOptions = {
      chartData: {
        labels: Array.from({ length: timestamps.length }, () => ''),
        datasets,
      },
      chartOptions: getLineChartOptions(scales, timestamps, vesselLastUpdated, 'Analytics-MassFlowRate', true) as any,
    }
  }

  useEffect(() => {
    setChartData(null)
  }, [startTime, endTime])

  useEffect(() => {
    if (isFetching || !data) return
    const worker = new Worker(lineRangeChartWorker)
    worker.postMessage({ graphData: data, tzOffset: offset })
    worker.onmessage = (e: MessageEvent) => {
      setChartData(e.data)
      dispatch(changeChartIsRendering(false))
    }
    return () => {
      worker.terminate()
    }
  }, [data, isFetching, offset, dispatch])

  return (
    <ChartWrapper
      title={chartTitle}
      className="h-96 MassFlowRate"
      tooltipText={tooltipText}
      tooltipId={tooltipId}
    >
      {isFetching || !chartDataAndOptions ? (
        <Loader />
      ) : !dataAvailable ? (
        <NoData
          type="warning"
          title="No data available for selected period"
          text="Please, try different date range."
        />
      ) : (
        <Chart
          options={chartDataAndOptions.chartOptions}
          type="line"
          data={chartDataAndOptions.chartData}
        ></Chart>
      )}
    </ChartWrapper>
  )
}

export default MassFlowRate
